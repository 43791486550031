/*
  Automatically instantiates modules based on data-attributes
  specifying module file-names.
*/

const dataModules = [...document.querySelectorAll('[data-module]')];

dataModules.forEach((element) => {
  element.dataset.module.split(' ').forEach(function (moduleName) {
    import(/* webpackChunkName: "[request]" */ `./${moduleName}`).then(
      (Module) => {
        new Module.default(element);
      }
    );
  });
});

/*
  Usage:
  ======

  html
  ----
  <button data-module="disappear">disappear!</button>

  js
  --
  // modules/disappear.js
  export default class Disappear {
    constructor(el) {
      el.style.display = 'none'
    }
  }
*/
