(($) => {
    'use strict';

    $('.dynamic-number').each(function () {
        const element = $(this);
        let currentNumber = element.text();
        let number = element.attr('id');

        if (number.includes('.')) {
            let finalNumber = parseFloat(number);
            $({ numberValue: currentNumber }).animate({ numberValue: finalNumber }, {
                duration: 2000,
                easing: 'linear',
                step: function (now) {
                    element.text(now.toFixed(1));
                }
            });
        } else {
            let finalNumber = parseInt(number, 10);
            $({ numberValue: currentNumber }).animate({ numberValue: finalNumber }, {
                duration: 2000,
                easing: 'linear',
                step: function (now) {
                    element.text(now.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                }
            });
        }
    });
})($);

