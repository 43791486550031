
(($) => {
  'use strict';

  $('.custom-select').each(function () {
    const element = $(this);
    let classes = element.attr('class');
    const name = element.attr('name'),
      placeholder = element.find(":selected").text(),
      selected = element.find(":selected").attr('value');

    let template = '<div class="select-container ' + classes + '">';
    template +=
      `<span class="custom-select__trigger select-filter-title" id="${selected}" name="${placeholder}">` +
      placeholder +
      '</span>';
    template += '<div class="custom-options">';
    element.find('option').each(function () {
      template +=
        '<span class="custom-option ' +
        $(this).attr('class') +
        '" data-value="' +
        $(this).attr('value') +
        '">' +
        $(this).html() +
        '</span>';
    });

    template += '</div></div>';

    element.wrap(
      `<div id="${name}" class="custom-select__wrapper" ></div>`
    );
    element.hide();
    element.after(template);
  });

  $('.custom-option:first-of-type').hover(
    function () {
      $(this).parents('.custom-options').addClass('option-hover');
    },
    function () {
      $(this).parents('.custom-options').removeClass('option-hover');
    }
  );

  $('body').on('click', '.custom-select__wrapper', (e) => {
    e.stopPropagation();
    const $element = $(e.currentTarget); e

    $('html').one('click', function () {
      $element.find('.custom-select').removeClass('opened');
    });

    $element.find('.custom-select').toggleClass('opened');
  });

  $('.custom-option').on('click', function () {
    const element = $(this);
    const value = element.data('value');
    let url = document.baseURI;
    let isSolutions = element.parents('.custom-select').hasClass('solutions');
    let isMarket = element.parents('.custom-select').hasClass('market');
    let marketValue = $(".market").find(".custom-select__trigger").attr("id");
    let solutionsValue = $(".solutions").find(".custom-select__trigger").attr("id");

    if (url.includes("?")) {
      let index = url.indexOf("?");
      url = document.baseURI.substring(0, index);
    }

    if (isSolutions) {
      if (marketValue !== "#" && value === '#') {
        window.location.href = `${url}?market-taxonomy=${marketValue}`;
      } else if (marketValue === "#" && value !== '#') {
        window.location.href = `${url}?solutions-taxonomy=${value}`;
      } else if (marketValue !== "#" && value !== '#') {
        window.location.href = `${url}?market-taxonomy=${marketValue}&solutions-taxonomy=${value}`;
      } else {
        window.location.href = url;
      }
    } else if (isMarket) {
      if (solutionsValue !== "#" && value === '#') {
        window.location.href = `${url}?solutions-taxonomy=${solutionsValue}`;
      } else if (solutionsValue === "#" && value !== '#') {
        window.location.href = `${url}?market-taxonomy=${value}`;
      } else if (solutionsValue !== "#" && value !== '#') {
        window.location.href = `${url}?market-taxonomy=${value}&solutions-taxonomy=${solutionsValue}`;
      } else {
        window.location.href = url;
      }
    }

    element
      .parents('.custom-select__wrapper')
      .find('.alm-filter--item')
      .val(element.data('value'))
      .trigger('change');
    element
      .parents('.custom-options')
      .find('.custom-option')
      .removeClass('selection');
    element.addClass('selection');
    element.parents('.custom-select').removeClass('opened');
    element
      .parents('.custom-select')
      .find('.custom-select__trigger')
      .text(element.text());
  });


  $('.child-link').hover(
    function () {
      $(this).find(".child-list").addClass('show');
      $(this).find(".child-list").removeClass('hide');
    },
    function () {
      $(this).find(".child-list").removeClass('show');
      $(this).find(".child-list").addClass('hide');
    }
  );

  $('html').one('click', function () {
    $('.custom-select').removeClass('opened');
  });
  $(this).parents('.custom-select').toggleClass('opened');
})($);
