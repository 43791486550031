(($) => {
  'use strict';

  $('.slide-right').on('click', function () {
    $('.slide-left svg').attr('style', 'opacity: 1');
    const lastPosition = $('#timeline-alt__container').scrollLeft();
    const position = $('#timeline-alt__container').scrollLeft() + 100;
    $('#timeline-alt__container').scrollLeft(position);
    if (lastPosition == $('#timeline-alt__container').scrollLeft()) {
      $('.slide-right svg').attr('style', 'opacity: 0.5');
    }
  });

  $('.slide-left').on('click', function () {
    $('.slide-right svg').attr('style', 'opacity: 1');
    const position = $('#timeline-alt__container').scrollLeft() - 100;
   
    $('#timeline-alt__container').scrollLeft(position);
    if (position == -100) {
      $('.slide-left svg').attr('style', 'opacity: 0.5');
    }
  });

  $('.time').on('click', function () {
    const element = $(this);
    const content = element.parents('.timeline-alt__item').find('.modal-content-item').clone(true, true);
    const modal = $('.modal');
    const container = modal.find('.modal-container');
    container.empty().append(content);
    modal.attr('style', 'display: block');
  });

  $('.close-icon').on('click', function () {
    const element = $(this);
    const modal = element.parents('.modal');
    modal.attr('style', 'display: none');
  });

})($);
